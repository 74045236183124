import gql from "graphql-tag";

export const TimelineSuggestionFragment = gql`
  fragment TimelineSuggestionFragment on TimelineItem {
    _id
    title
    admin_title
    city
    suggestion {
      isRequired
      arrange_or_specific
      description
      readmore_text
      definitionflow_text
      firstmessage_text
    }
    batch_id # Batch numbers, or none if always available
    priority # Determines the order of the items while in "Active" timeline
  }
`;

export const SimpleClientFragment = gql`
  fragment SimpleClientFragment on Client {
    _id
    firstName
    lastName
    company {
      _id
      name
    }
    paidUntil
    createdAt
    phone
  }
`;

export const BatchFragment = gql`
  fragment BatchFragment on Batch {
    from
    till
    items {
      status
      credit_in_cents
      personalMessage
      dismiss_reason
      allowanceId
      remindedAt
      times_usable
      times_used
      from
      till
      todo {
        _id
        title
        region
      }
      item {
        ...TimelineSuggestionFragment
      }
    }
  }
  ${TimelineSuggestionFragment}
`;

export const ClientFragment = gql`
  fragment ClientFragment on Client {
    _id
    firstName
    lastName
    birthDate
    nickname
    email
    currency
    relocatingTo
    company {
      _id
      name
      batches {
        ...BatchFragment
      }
      topics {
        _id
        topic {
          _id
          title
          content {
            _id
            type
            body
            url
          }
        }
        todos {
          _id
          todo {
            _id
            title
            region
          }
          handle
          chat
          handleInstructions
          policy {
            title
            description
          }
        }
      }
    }
    paidUntil
    serviceStartDate
    phone
    role
    cluster
    profileType
    batches {
      ...BatchFragment
    }
    features {
      knowledgebase
      manuallyDisableChat
      communitytab
      chat
      orderOverview
      stay
    }
    allowances {
      _id
      title
    }
    tasks {
      _id
      type
      status
      batch
      from
      till
      url
    }
    initiationId
  }
  ${BatchFragment}
`;

export const CompanyFragment = gql`
  fragment CompanyFragment on Company {
    _id
    name
    other
    address
    isActive
    showPrivateBusiness
    isPilot
    clients {
      _id
      firstName
      lastName
    }
    branding {
      brandColor
      invertedTextColor
    }
    batches {
      ...BatchFragment
    }
    topics {
      _id
      topic {
        _id
        title
        content {
          _id
          type
          body
          url
        }
      }
      todos {
        _id
        todo {
          _id
          title
          region
        }
        handle
        chat
        handleInstructions
        policy {
          title
          description
        }
      }
    }
  }
  ${BatchFragment}
`;

export const HandlingPlanFragment = gql`
  fragment HandlingPlanFragment on HandlingPlan {
    _id
    enabled
    title
    description
    region
    content {
      _id
      type
      weight
      body
      url
      videoId
    }
    suppliers
    requirements
    sensitiveInfo
    steps {
      type
      phase
      title
      body
      questions {
        context
        label
      }
    }
    proposalExample
    considerations
    questionsAndAnswers {
      _id
      question
      answer
    }
    serviceId  
  }
`;

export const SupplierFullFragment = gql`
  fragment SupplierFullFragment on Supplier {
    _id
    supplier
    link
    city
    zipcode
    country
    address
    rating
    estimatedResponseTime
    handlingDuration
    tags {
      _id
      name
    }
    roadmap {
      title
      _id
    }
    contactPerson {
      firstName
      lastName
      gender
      email
      phoneNumber
      other
      eta
      preferredLanguage
      reachHours {
        name
        isOpen
        from
        till
      }
    }
    openingTimes {
      name
      isOpen
      from
      till
    }
    updatedBy
    updatedDate
    timesUsed
    timesChosen
    ubutlerPreferred
    tradeOff {
      # _id
      title
      isProposal
    }
    notes
    archived
  }
`;

export const TodoStepsFragment = gql`
  fragment TodoStepsFragment on AdminTodo {
    handling {
      steps {
        _id
        previousStepId
        type
        title
        maxDuration {
          minutes
          hours
          days
        }
        responsibility
        adviceForVB
        showConclusionInOverview

        needETA
        timeFrame {
          minutes
          hours
          days
        }

        instruction
        exampleMessage
        questions {
          _id
          required
          type
          key
          exampleQuestion
        }
        options {
          _id
          name
          description
          url
          adviceForClient
        }
        createProposalStepId
      }
    }
  }
`;

export const CoreStoryFields = gql`
  fragment CoreStoryFields on AdminStory {
    _id
    title
    connectedRegions
    createdAt
    isActive
  }
`;
