import React, { Component } from "react";
import { ApolloProvider } from "react-apollo";
import { ApolloProvider as ApolloHooksProvider } from "react-apollo-hooks";
import ApolloClient from "apollo-client";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ErrorBoundary } from "react-error-boundary";

import { TOKEN } from "./constants";
import EventListener from "react-event-listener";
import Login from "./Components/Login/Login";
import Portal from "./Components/Portal/Portal";
import config from "./config";
import { GraphqlErrorWell, Whitespace, Flex } from "./Components/Elements.js";

import { HttpLink } from "apollo-link-http";
import { onError } from "apollo-link-error";
import { InMemoryCache } from "apollo-cache-inmemory";
import Button from "@material-ui/core/Button";

import "./basic-styling/_basic.css";
import "./basic-styling/_grid.css";
import "./basic-styling/_variables.css";

import PhosphorIcon from "./Components/phosphor-icons/phosphor-icon.js";

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily: ["Lato", "Arial", "Helvetica", "sans-serif"].join(", "),
    fontWeight: "400",
  },
  palette: {
    primary: {
      main: "#00d89e",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#0B8FD6",
      contrastText: "#e4f8ff",
    },
  },
  overrides: {
    MuiButton: {
      root: {
        boxShadow: "none",
      },
      contained: {
        boxShadow: "none",
        fontWeight: "bold",
      },
    },
    MuiAppBar: {
      root: {
        zIndex: 1201,
        boxShadow: "none",
      },
    },
    MuiIconButton: {
      root: {
        fontSize: "1rem",
      },
    },
  },
});

class App extends Component {
  constructor(props) {
    super(props);

    const httpLink = new HttpLink({ uri: config.graphqlHttpUrl });

    const logoutLink = onError(arg => {
      console.log(`arg:`, arg);
      let errors = [];
      if (arg.graphQLErrors) {
        errors = arg.graphQLErrors;
      }
      if (arg.networkError) {
        errors.push(arg.networkError);
      }
      errors.forEach(error => {
        if (error.message === "authorization failed") {
          localStorage.removeItem(TOKEN);
          //this.props.history.push(`/`);
          window.location.reload(true);
        }
      });
    });

    const link = logoutLink.concat(httpLink);

    this.client = new ApolloClient({
      link,
      cache: new InMemoryCache(),
    });
  }

  render() {
    return (
      <>
        <ApolloHooksProvider client={this.client}>
          <ApolloProvider client={this.client}>
            <MuiThemeProvider theme={theme}>
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <div className="App">
                    <EventListener
                      target="window"
                      onStorage={e => {
                        if (e.key === TOKEN) {
                          this.reloadPage();
                        }
                      }}
                    />
                    {localStorage.getItem(TOKEN) ? <Portal /> : <Login />}
                  </div>
                </MuiPickersUtilsProvider>
              </ErrorBoundary>
            </MuiThemeProvider>
          </ApolloProvider>
        </ApolloHooksProvider>
      </>
    );
  }
}

const ErrorFallback = ({ error, componentStack, resetErrorBoundary }) => {
  let [show_stack, set_show_stack] = React.useState(false);

  return (
    <div className="modal-background">
      <div className="modal">
        <div>
          <h4>Error</h4>
        </div>
        <div className="error-message">
          <PhosphorIcon name="warning" />
          <p>{error.message}</p>
        </div>

        <Whitespace height={16} />
        <GraphqlErrorWell error={error} />

        {show_stack && (
          <div style={{ whiteSpace: "pre-wrap" }}>{componentStack}</div>
        )}

        <Whitespace height={16} />

        <Flex row style={{ justifyContent: "space-between" }}>
          <div className="modal-actions">
            <Button
              onClick={() => set_show_stack(!show_stack)}
              color="primary"
              autoFocus
            >
              {show_stack ? "Hide" : "Show"} stack
            </Button>
          </div>

          <div className="modal-actions">
            <Button
              onClick={() => window.location.reload(true)}
              color="primary"
              autoFocus
            >
              Reload page
            </Button>
            <Button onClick={() => resetErrorBoundary()} color="secondary">
              Dismiss
            </Button>
          </div>
        </Flex>
      </div>
    </div>
  );
};

export default App;
