import React, { useState } from "react";
import {
  Route,
  Link,
  useRouteMatch,
  useLocation,
  useHistory,
  Switch,
} from "react-router-dom";
import {
  Card,
  DatePicker,
  Button,
  TextInput,
  NumberInput,
  Whitespace,
  Select,
} from "../Elements.js";
import { KeyboardDatePicker } from "@material-ui/pickers";
import uuid from "uuid";
import moment from "moment";
import immer from "immer";
import styled from "styled-components";
import gql from "graphql-tag";
import { useMutation, getToken } from "../../Graphql.js";

const TaskTypes = {
  period_evaluation: {
    title: "Period evaluation",
  },
  schedule_video_call: {
    title: "Schedule video call",
    url: true,
  },
  final_call: {
    title: "Schedule final call",
    url: true,
  },
  community_event: {
    title: "Community event",
    url: true,
  },
};

const StatusTypes = {
  Active: "ACTIVE",
  Redeemed: "REDEEMED",
  Dismissed: "DISMISSED",
};

const CALENDLY_URL = "https://calendly.com/ubutler";

const validateUrl = (taskType, url) => {
  if (taskType === "community_event") return url.trim();
  if (url && url.startsWith(CALENDLY_URL)) {
    return url.trim();
  }
  return null;
};

const TaskForm = ({
  onTaskChange,
  onCancel,
  task: taskProp = {},
  maxPeriod,
}) => {
  const [task, setTask] = useState(taskProp);

  return (
    <>
      <div style={{ display: "flex" }}>
        <Select
          label="Type"
          items={Object.entries(TaskTypes).map(entry => {
            const [value, { title }] = entry;
            return { title, value };
          })}
          onChange={e => {
            const attrs = {
              type: e.target.value,
              status: task.status || StatusTypes.Active,
            };
            if (!TaskTypes[e.target.value].url) {
              attrs.url = null;
            }
            setTask({ ...task, ...attrs });
          }}
          value={task.type}
        />
        <Whitespace width={8} />
        <Select
          label="Status"
          items={Object.entries(StatusTypes).map(([title, value]) => ({
            title,
            value,
          }))}
          onChange={e => {
            setTask({ ...task, status: e.target.value });
          }}
          value={task.status}
        />
      </div>
      <Whitespace height={8} />
      {task.type && TaskTypes[task.type].url && (
        /*
          If no Calendly url is provided then a default Calendly prop url will be used
          in nativebase-app/components/ScheduleCallScreen.js
        */
        <>
          <TextInput
            label="Url"
            onChange={e => {
              setTask({ ...task, url: e.target.value });
            }}
            placeholder="Add url in https format"
            value={task.url || ""}
          />
          <Whitespace height={8} />
        </>
      )}
      <div style={{ display: "flex" }}>
        <Select
          label="Period"
          // TODO get number of EvaluationTypes
          items={[...Array(4)].map((_, i) => {
            i += 1;
            return { title: i, value: i };
          })}
          onChange={e => {
            setTask({ ...task, batch: e.target.value });
          }}
          value={task.batch}
        />
        <Whitespace width={8} />
        <DatePicker
          label="From"
          value={task.from}
          onChange={d => {
            const dateStr = moment(d).toISOString();
            setTask({ ...task, from: dateStr });
          }}
          fullWidth={true}
        />
        <Whitespace width={8} />
        <DatePicker
          label="Till"
          value={task.till}
          onChange={d => {
            const dateStr = moment(d).toISOString();
            setTask({ ...task, till: dateStr });
          }}
          fullWidth={true}
        />
      </div>
      <Whitespace height={8} />

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          onClick={() => onTaskChange(task)}
          disabled={
            !Boolean(
              task.type &&
                task.status &&
                task.batch &&
                task.from &&
                task.till &&
                task.from < task.till
            )
          }
        >
          Save
        </Button>
        <Whitespace width="4px" />
        <Button
          onClick={onCancel}
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            color: "rgba(0,0,0)",
          }}
        >
          Cancel
        </Button>
      </div>
    </>
  );
};

const Task = ({ canEdit, task, onTaskChange, onDelete, maxPeriod }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  return (
    <TaskContainer>
      {isCollapsed ? (
        <>
          <div style={{ display: "flex" }}>
            <TextInput
              label="Type"
              value={TaskTypes[task.type].title}
              InputProps={{
                readOnly: true,
              }}
              variant="standard"
            />
            <Whitespace width={8} />
            <TextInput
              label="Type"
              value={task.status}
              InputProps={{
                readOnly: true,
              }}
              variant="standard"
            />
          </div>
          <Whitespace height={8} />
          {TaskTypes[task.type].url && (
            <>
              <TextInput
                label="Calendly url"
                value={task.url || ""}
                InputProps={{
                  readOnly: true,
                }}
                variant="standard"
              />
              <Whitespace height={8} />
            </>
          )}
          <div style={{ display: "flex" }}>
            <NumberInput
              label="Period"
              value={task.batch || 0}
              InputProps={{
                readOnly: true,
              }}
              variant="standard"
            />
            <Whitespace width={8} />
            <KeyboardDatePicker
              label="From"
              value={task.from}
              disabled
              variant="standard"
              fullWidth={true}
            />
            <Whitespace width={8} />
            <KeyboardDatePicker
              label="Till"
              value={task.till}
              disabled
              variant="standard"
              fullWidth={true}
            />
          </div>
          {canEdit && (
            <>
              <Whitespace height={8} />
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button onClick={() => setIsCollapsed(false)}>Edit</Button>
                <Whitespace width="4px" />
                <Button
                  onClick={() => onDelete(task)}
                  style={{
                    backgroundColor: "rgba(0, 0, 0, 0.2)",
                    color: "rgba(0,0,0)",
                  }}
                >
                  Delete
                </Button>
              </div>
            </>
          )}
        </>
      ) : (
        <TaskForm
          onCancel={() => setIsCollapsed(true)}
          task={task}
          maxPeriod={maxPeriod}
          onTaskChange={async task => {
            await onTaskChange(task);
            setIsCollapsed(true);
          }}
        />
      )}
    </TaskContainer>
  );
};

export const ClientTasks = ({ client, onClientChange }) => {
  const { tasks = [], batches = [] } = client;
  const maxPeriod = batches.length;
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const [addReminder] = useMutation(ADD_REMINDER_MUTATION);

  return (
    <Card.Content>
      <Switch>
        <Route
          path={`${match.url}/new`}
          render={() => (
            <TaskContainer>
              <p>New task</p>
              <Whitespace height={8} />
              <TaskForm
                key="new-bucket"
                maxPeriod={maxPeriod}
                onCancel={() => history.goBack()}
                onTaskChange={async task => {
                  await onClientChange({
                    ...client,
                    tasks: [
                      ...tasks,
                      {
                        _id: uuid.v4(),
                        type: task.type,
                        status: task.status,
                        batch: task.batch,
                        from: task.from,
                        till: task.till,
                        url: validateUrl(task.type, task.url),
                      },
                    ],
                  });

                  if (task.type === "period_evaluation") {
                    await addReminder({
                      variables: {
                        token: getToken(),
                        clientId: client._id,
                        dueDate: task.from,
                        content:
                          "A review is available for the GE. Send a message to inform about it.",
                      },
                    });
                  }

                  history.goBack();
                }}
              />
            </TaskContainer>
          )}
        />
        <Route>
          {tasks
            .filter(task => TaskTypes.hasOwnProperty(task.type))
            .map(task => {
              return (
                <>
                  <Task
                    task={task}
                    key={task._id}
                    maxPeriod={maxPeriod}
                    canEdit={!Boolean(location.pathname.includes("new"))}
                    onTaskChange={async task => {
                      const { _id } = task;
                      task.url = validateUrl(task.type, task.url);
                      const index = tasks.findIndex(task => task._id === _id);

                      const updatedTasks = immer(tasks, draft => {
                        draft.splice(index, 1, task);
                      });

                      if (task.type === "period_evaluation") {
                        await addReminder({
                          variables: {
                            token: getToken(),
                            clientId: client._id,
                            dueDate: task.from,
                            content:
                              "A review is available for the GE. Send a message to inform about it.\n\n" +
                              "(updated in admin portal, please delete previous reminder)",
                          },
                        });
                      }

                      return await onClientChange({
                        ...client,
                        tasks: updatedTasks,
                      });
                    }}
                    onDelete={async task => {
                      const { _id } = task;
                      const index = tasks.findIndex(task => task._id === _id);

                      const updatedTasks = immer(tasks, draft => {
                        draft.splice(index, 1);
                      });

                      return await onClientChange({
                        ...client,
                        tasks: updatedTasks,
                      });
                    }}
                  />
                  <Whitespace height={30} />
                </>
              );
            })}
          <Link to={`${match.url}/new`}>
            <Button disabled={Boolean(location.pathname.includes("new"))}>
              +
            </Button>
          </Link>
        </Route>
      </Switch>
    </Card.Content>
  );
};

const TaskContainer = styled.div`
  width: 100%;
  padding: 21px;
  border-radius: 5px;
  margin-bottom: 21px;
  color: #2a2135;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
`;

const ADD_REMINDER_MUTATION = gql`
  mutation(
    $token: String!
    $clientId: String!
    $dueDate: Date!
    $content: String
  ) {
    addReminder(
      token: $token
      clientId: $clientId
      dueDate: $dueDate
      content: $content
    ) {
      _id
    }
  }
`;
